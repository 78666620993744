import { FC, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useOrsysContext } from '@orsys/common';

export const IntercomComponent: FC = () => {
  const { user } = useOrsysContext();
  const { update } = useIntercom();

  useEffect(() => {
    if (user) {
      update({
        userId: `${user?.id}`,
        email: user.email,
        name: `${user?.firstName} ${user?.lastName}`
      });
    }
  }, [user]);

  return (
    <>
    </>
  );
};
