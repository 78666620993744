import 'leaflet/dist/leaflet.css';
import 'react-date-range/dist/styles.css';
// import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'libs/common/styles/globals.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Bugsnag from 'libs/common/src/utils/Bugsnag';
import OrsysContextProvider from 'libs/common/src/utils/OrsysContext';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import Alert from '../src/components/widgets/Alert/Alert';
import BrowserHeader from '../src/components/widgets/Header/BrowserHeader';
import { getTranslations } from 'libs/common/src/utils/i18n';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HydrationBoundary, keepPreviousData, QueryClient } from '@tanstack/react-query';
import AcceptTOS from '../src/components/AcceptTOS/AcceptTOS';
import dynamic from 'next/dynamic';
import { Persister, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { IntercomProvider } from 'react-use-intercom';
import { PublicRuntimeConfig } from '@orsys/config';
import { IntercomComponent } from '../src/components/IntercomComponent/IntercomComponent';

const { intercomAPPId } = PublicRuntimeConfig;

// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const HotjarComponent = dynamic(() => import('../src/components/HotjarComponent'), { ssr: false });

function MyApp({ Component, pageProps }: AppProps<any>) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            placeholderData: keepPreviousData,
            gcTime: 1000 * 60 // 60 seconds
          }
        }
      })
  );
  const [messages, setMessages] = useState({});
  const [persister, setPersister] = useState<Persister>(
    createSyncStoragePersister({
      storage: undefined
    })
  );

  useEffect(() => {
    setMessages(getTranslations(pageProps.locale));
    setPersister(
      createSyncStoragePersister({
        storage: window.localStorage
      })
    );
  }, [pageProps.locale]);

  return (
    <>
      <BrowserHeader />
      <ErrorBoundary>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}>
          <HydrationBoundary state={pageProps.dehydratedState}>
            <IntlProvider
              locale={pageProps.locale}
              messages={messages}>
              <IntercomProvider appId={intercomAPPId} autoBoot>
                <OrsysContextProvider>
                  <ToastContainer
                    position="top-right"
                    autoClose={false}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                  <IntercomComponent />
                  <Component {...pageProps} />
                  <Alert />
                  <AcceptTOS />
                  <HotjarComponent />
                </OrsysContextProvider>
              </IntercomProvider>
            </IntlProvider>
          </HydrationBoundary>
          <ReactQueryDevtools initialIsOpen={false} />
        </PersistQueryClientProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
